import { Section } from "../../../../packages/editing/Section"
import { useQueryString } from "../../../../reactor/Web"
import { usePhoneOrder } from "../client"
import { Heading } from "../ui/components/typography/Heading"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"

Section(OrderView)
function OrderView(section: {}) {
    const [orderId] = useQueryString("id")
    const order = usePhoneOrder((orderId as any) ?? null)
    const localize = useLocalize()

    if (!order.data) return <></>
    const { phone } = order.data

    return (
        <div style={{ backgroundColor: "#fee", padding: 32 }}>
            <Heading level={2}>{localize(phone.name)}</Heading>
            {orderId && <OrderReceipts orderId={orderId} />}
        </div>
    )
}

function OrderReceipts(props: { orderId: string }) {
    const order = usePhoneOrder((props.orderId as any) ?? null)
    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                Your receipts
            </Heading>

            {order.data?.receipts.map((receipt) => (
                <a
                    href={`/api/phone-orders/${props.orderId}/pdf-receipts/${receipt.id}`}
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        maxWidth: 400,
                        borderTop: "1px solid #ddd",
                    }}
                >
                    <div style={{ padding: 16 }}>{receipt.date}</div>
                    <div style={{ padding: 16 }}>
                        {receipt.total.majorUnits} {receipt.total.currency}
                    </div>
                </a>
            ))}
        </>
    )
}
