import { Component } from "../../../../../../packages/editing/Component"
import { colors } from "../../constants/colors"
import { css, resetStyles, responsiveCss } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Body } from "../typography/Body"
import { Heading } from "../typography/Heading"
import { Text } from "../typography/Text"
import { Icon } from "../visual/Icon"

export function BoxSelection(props: {
    primary?: string
    secondary?:
        | string
        | {
              price: string
              currency: string
              period: string
          }

    text?: string
    list?: string[]
    link?: {
        text: string
        url: string
    }
    selected?: boolean
    /**
     * @reflection any
     */
    style?: React.CSSProperties
    onClick?: () => void
}) {
    return (
        <Flex
            onClick={props.onClick}
            backgroundColor={props.selected ? "brandLight" : "grayWhite"}
            borderColor={props.selected ? "brand" : "gray200"}
            borderRadius="md"
            style={{ padding: 24, cursor: "pointer", ...props.style }}
        >
            <div
                style={{ display: "grid", width: "100%" }}
                css={css(
                    responsiveCss("max", "sm", { gap: 8 }),
                    responsiveCss("min", "md", { gridTemplateColumns: "0.8fr 1fr", gap: 40 })
                )}
            >
                <Flex
                    gap={8}
                    justifyContent="space-between"
                    css={css(
                        responsiveCss("max", "sm", { flexDirection: "column-reverse" }),
                        responsiveCss("min", "md", { flexDirection: "column" })
                    )}
                >
                    {typeof props.primary !== "undefined" && (
                        <>
                            <span
                                style={{
                                    marginBottom: 32,
                                    color: colors.brand,
                                }}
                                css={css(responsiveCss("max", "sm", { display: "none" }))}
                            >
                                <Text variant="body" size="xl" style={{ fontWeight: 400 }}>
                                    {props.primary}
                                </Text>
                            </span>
                            <span css={css(responsiveCss("min", "md", { display: "none" }))}>
                                <Text variant="body" size="lg" style={{ fontWeight: 400 }}>
                                    {props.primary}
                                </Text>
                            </span>
                        </>
                    )}
                    {typeof props.secondary !== "undefined" &&
                        (typeof props.secondary === "string" ? (
                            <Text variant="body" size="xl">
                                {props.secondary}
                            </Text>
                        ) : (
                            <span
                                css={css(
                                    responsiveCss("max", "sm", {
                                        color: colors.brand,
                                        fontWeight: 600,
                                    })
                                )}
                            >
                                <span css={css(responsiveCss("max", "sm", { display: "none" }))}>
                                    <Text as="span" variant="heading" level="3">
                                        {props.secondary.price} {props.secondary.currency}.
                                    </Text>
                                    /{props.secondary.period}
                                </span>
                                <span css={css(responsiveCss("min", "md", { display: "none" }))}>
                                    <Text
                                        as="span"
                                        variant="heading"
                                        level="3"
                                        style={{ fontWeight: 600 }}
                                    >
                                        {props.secondary.price} {props.secondary.currency}{" "}
                                        {props.secondary.period}.
                                    </Text>
                                </span>
                            </span>
                        ))}
                </Flex>
                <Flex direction="column" justifyContent="space-between">
                    {typeof props.list !== "undefined" ? (
                        <ul css={resetStyles("ul")}>
                            {props.list.map((item) => (
                                <li
                                    key={item}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 8,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Icon icon="check" color="brand" />
                                    <Body size="md" color="gray400">
                                        {item}
                                    </Body>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    {typeof props.text !== "undefined" && (
                        <Body size="md" color="gray400">
                            {props.text}
                        </Body>
                    )}

                    {typeof props.link !== "undefined" && (
                        <a
                            css={css(
                                responsiveCss("max", "sm", { marginTop: 8 }),
                                responsiveCss("min", "md", { marginTop: 32 })
                            )}
                            href={props.link.url}
                        >
                            <Body
                                size="sm"
                                color="gray400"
                                style={{ textAlign: "right" }}
                                underline
                            >
                                {props.link.text}
                            </Body>
                        </a>
                    )}
                </Flex>
            </div>
        </Flex>
    )
}

Component(BoxSelection, {
    name: "BoxSelection",
    gallery: {
        path: "Controllers/BoxSelection",
        items: [
            {
                title: "Selected",
                variants: [
                    {
                        props: {
                            primary: "Mobilforsikring fra Gjensidige",
                            secondary: "50 kr/md",
                            list: [
                                "Erstatning ved tyveri/tap mot egenandel.",
                                "Send mobilen inn gratis.",
                                "Motta direkte betaling til din konto.",
                            ],
                            link: { text: "Vilkår og betingelser", url: "#" },
                            selected: true,
                        },
                    },
                ],
            },
            {
                title: "Not selected",
                variants: [
                    {
                        props: {
                            secondary: "Ingen forsikringsdekning",
                            text: "Du er selv ansvarlig for verdien av mobilen, som er 10 000kr, hvis den blir skaded, mistet eller stjålet.",
                        },
                    },
                ],
            },
        ],
    },
})
