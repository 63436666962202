import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { usePhoneOrder } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { useCheckoutContext } from "./CheckoutContext"

export type ConfirmationStepProps = {
    headline: Localized<string>
}
export function ConfirmationStep(props: ConfirmationStepProps) {
    const { orderId } = useCheckoutContext()
    const order = usePhoneOrder(orderId ?? null)

    const localize = useLocalize()
    return (
        <div style={{ padding: 32 }}>
            <Heading level={2}>{localize(props.headline)}</Heading>
            {JSON.stringify(order.data)}
        </div>
    )
}
