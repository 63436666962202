import { Fragment } from "react"
import { css } from "../ui/helpers/css"
import { Assert, Image, Uuid } from "../../../../reactor"
import { Section } from "../../../../packages/editing/Section"
import { WebPage } from "../../../../packages/web/model/WebPage"
import { Localized } from "../../../../packages/localization/Localized"
import { ProductCard } from "../ui/components/cards/ProductCard"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Heading } from "../ui/components/typography/Heading"
import { Box } from "../ui/components/base/Box"
import { Flex } from "../ui/components/base/Flex"
import { responsiveCss, responsiveSectionSpacing, responsiveSpacing } from "../ui/helpers/css"
import { Color, colors } from "../ui/constants/colors"
import { SeeAllCard } from "../ui/components/cards/SeeAllCard"
import { usePhoneOffers } from "../client"
import { openWaitingListSignupModal } from "./WaitingListSignupModal"
import { useWebPageInfo } from "../../../../studio/client"

export type PromotedProductSeeAll = {
    heading: Localized<string>

    /**
     * Call to action button on "see all" section.
     */
    cta: {
        text: Localized<string>

        /**
         * The page with all the products.
         */
        url: WebPage["id"]
    }

    /**
     * Image to show at the bottom of the block.
     */
    image: Image
}

export type PromotedProductCta = {
    /**
     * Text for the CTA button.
     */
    text: Localized<string>

    /**
     * The page to open when the CTA button is clicked.
     */
    url: WebPage["id"]

    /**
     * Always show the CTA button on the product card, not just on hover, which is the default
     * behavior.
     */
    alwaysShow?: boolean
}

/**
 * Same as PromotedProducts, presenting 4 promoted products from the product catalogue, but with
 * the products automatically selected based on some criteria.
 */
function PromotedProductsAuto(section: {
    /**
     * The heading for this section
     */
    heading: Localized<string>

    /**
     * The call to action button on each product card. URL should point to general product page
     * and then the product id will be passed as parameter.
     */
    productCta: PromotedProductCta

    /**
     * See all block with a photo and a link to all products.
     */
    seeAll: PromotedProductSeeAll
}) {
    const offers = usePhoneOffers()
    if (!offers.data) return <>No data</>

    return (
        <PromotedProducts
            {...section}
            disableWaitingListModal={true}
            products={offers.data.map((o) => ({
                id: o.id,
                name: o.name,
                description: o.shortDescription ?? { en: "(no description)" },
                price: {
                    no: `${o.monthlyPrice.majorUnits.toString()} kr/mnd`,
                    en: `${o.monthlyPrice.majorUnits.toString()} NOK/mo`,
                },
                image: o.images?.[0],
            }))}
        />
    )
}
Section(PromotedProductsAuto)

/**
 * Section presenting 4 promoted products from the product catalogue. Renders as a grid on desktop
 * and list on mobile.
 */
function PromotedProducts(section: {
    /**
     * The heading for this section
     */
    heading: Localized<string>

    /**
     * The call to action button on each product card. URL should point to general product page
     * and then the product id will be passed as parameter.
     */
    productCta: PromotedProductCta

    /**
     * See all block with a photo and a link to all products.
     */
    seeAll: PromotedProductSeeAll

    /**
     * The products to show.
     *
     * @expand
     */
    products: {
        readonly id: Uuid<"Phone">
        name: Localized<string>
        description: Localized<string>
        price: Localized<string>
        image?: Image

        /**
         * Toggle on if price should be highlighted in brand color.
         */
        highlightPrice?: boolean

        /**
         * Show a label to bring more attention to the product, e.g. "new" or "deal".
         *
         * @expand
         */
        label?: {
            text: Localized<string>
            color: Color
        }
    }[]

    /**
     * A generic fallback image to show on a product card if the product does not have an image.
     */
    fallbackProductImage?: Image

    /**
     * The default implementation, for legacy reasons, will display the waiting
     * list modal. Setting this to `true` will disable that behavior and instead
     * follow the `cta.url` link.
     */
    disableWaitingListModal?: boolean
}) {
    const localize = useLocalize()
    const pageInfo = useWebPageInfo(section.productCta.url ?? null)

    return (
        <Flex direction="column" css={css(responsiveSectionSpacing())}>
            <div css={css({ marginBottom: 16 }, responsiveCss("min", "md", { marginBottom: 24 }))}>
                <Heading level={2}>{localize(section.heading)}</Heading>
            </div>
            <Box
                css={css(
                    {
                        display: "grid",
                        gridAutoFlow: "row",
                        gridTemplateRows: "1fr",
                    },
                    responsiveCss("min", "md", {
                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                        gridTemplateAreas: '"C1 C2 C3" "C4 AA AA"',
                    }),
                    responsiveSpacing("sm", "gap"),
                    // Increased gap for mobile with a different layout.
                    responsiveCss("max", "sm", {
                        gap: 24,
                    })
                )}
            >
                {section.products.slice(0, 4).map((p, i) => (
                    <Fragment key={p.id.valueOf()}>
                        <Box
                            css={css(
                                responsiveCss("max", "sm", {
                                    display: "none",
                                }),
                                responsiveCss("min", "md", {
                                    gridArea: `C${i + 1}`,
                                })
                            )}
                        >
                            <ProductCard
                                onClick={
                                    section.disableWaitingListModal
                                        ? undefined
                                        : () => openWaitingListSignupModal("product-card")
                                }
                                alwaysShowCta={section.productCta.alwaysShow}
                                name={localize(p.name)}
                                description={localize(p.description)}
                                price={localize(p.price)}
                                highlightPrice={p.highlightPrice}
                                image={p.image}
                                cta={{
                                    text: localize(section.productCta.text),
                                    url: `/${localize(pageInfo.data?.slug ?? { en: "products" })}?phone_model=${p.id.valueOf()}`,
                                }}
                                label={
                                    p.label
                                        ? {
                                              text: localize(p.label.text),
                                              color: p.label.color,
                                          }
                                        : undefined
                                }
                            />
                        </Box>
                        <Flex
                            direction="column"
                            css={css(
                                i > 0 &&
                                    css`
                                        &:before {
                                            content: "";
                                            border-bottom: 1px solid ${colors.gray200};
                                            margin-bottom: 24px;
                                        }
                                    `,
                                responsiveCss("min", "md", {
                                    display: "none",
                                })
                            )}
                        >
                            <ProductCard
                                onClick={
                                    section.disableWaitingListModal
                                        ? undefined
                                        : () => openWaitingListSignupModal("product-card")
                                }
                                name={localize(p.name)}
                                description={localize(p.description)}
                                price={localize(p.price)}
                                image={p.image}
                                highlightPrice={p.highlightPrice}
                                label={
                                    p.label
                                        ? {
                                              text: localize(p.label.text),
                                              color: p.label.color,
                                          }
                                        : undefined
                                }
                                cta={{
                                    text: localize(section.productCta.text),
                                    url: section.productCta.url.valueOf(),
                                }}
                                compact
                            />
                        </Flex>
                    </Fragment>
                ))}
                <Box
                    css={css(
                        responsiveCss("max", "xs", { height: 280 }),
                        responsiveCss("min", "sm", { minHeight: 372 }),
                        responsiveCss("min", "md", { gridArea: "AA" })
                    )}
                >
                    <SeeAllCard
                        heading={localize(section.seeAll.heading)}
                        cta={{
                            text: localize(section.seeAll.cta.text),
                            url: section.seeAll.cta.url.valueOf(),
                        }}
                        image={section.seeAll.image}
                    />
                </Box>
            </Box>
        </Flex>
    )
}

Section(PromotedProducts)
