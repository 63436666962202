import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { useInsuranceOptions } from "../../client"
import { BoxSelection } from "../../ui/components/controllers/BoxSelection"
import { Heading } from "../../ui/components/typography/Heading"
import { useCheckoutContext } from "./CheckoutContext"
import { Button } from "../../ui/components/buttons/Button"
import { Flex } from "../../ui/components/base/Flex"
import { css, responsiveCss } from "../../ui/helpers/css"

export type InsuranceStepProps = {
    /**
     * @default '{"en":"Insurance"}'
     */
    headline: Localized<string>

    noInsuranceHeader: Localized<string>
    noInsuranceText: Localized<string>

    primaryCta: Localized<string>
    notThisTimeButton: Localized<string>
}

export function InsuranceStep(props: InsuranceStepProps) {
    const localize = useLocalize()
    const { offer, setOffer, setStep } = useCheckoutContext()
    const insuranceOptions = useInsuranceOptions(
        offer.model,
        offer.storage,
        offer.color,
        offer.rentalPeriod
    )
    const selected = insuranceOptions.data?.findIndex((io) => io.id === offer.insurance)

    return (
        <Flex
            direction="column"
            css={css(
                responsiveCss("max", "sm", { gap: 16 }),
                responsiveCss("min", "md", { padding: 32, gap: 32 })
            )}
        >
            <Heading level={2}>{localize(props.headline)}</Heading>

            <Flex direction="column" gap={16}>
                {insuranceOptions.data?.map((option, i) => (
                    <BoxSelection
                        onClick={() => setOffer({ ...offer, insurance: option.id })}
                        selected={selected === i}
                        primary={localize(option.displayName)}
                        secondary={{
                            price: option.pricePerMonth || "50",
                            currency: localize({ en: "NOK", no: "kr" }),
                            period: localize({ en: "month", no: "mo" }),
                        }}
                        list={option.sellingPoints.map((sp) => localize(sp))}
                        link={{ url: "", text: localize(option.termsAndConditions) }}
                    />
                ))}
                <BoxSelection
                    onClick={() => setOffer({ ...offer, insurance: undefined })}
                    selected={selected === -1}
                    secondary={localize(props.noInsuranceHeader)}
                    text={localize(props.noInsuranceText)}
                />
            </Flex>

            <Flex
                gap={8}
                justifyContent="right"
                alignItems="center"
                css={css(responsiveCss("max", "sm", { flexDirection: "column-reverse" }))}
            >
                <Button
                    css={css(responsiveCss("max", "sm", { display: "none" }))}
                    variant="secondary"
                    onClick={() => {
                        setOffer({ ...offer, insurance: undefined })
                        setStep("Login")
                    }}
                >
                    {localize(props.notThisTimeButton)}
                </Button>
                <Button variant="primary" onClick={() => setStep("Login")}>
                    {localize(props.primaryCta)}
                </Button>
            </Flex>
        </Flex>
    )
}
