import { Component } from "../../../../../../packages/editing/Component"
import { Flex } from "../base/Flex"
import { RedoitStars } from "../visual/RedoitStars"
import { Body } from "../typography/Body"
import { Text } from "../typography/Text"
import { Heading } from "../typography/Heading"
import { css, responsiveCss, scaleValue } from "../../helpers/css"

export function ReviewCard(props: {
    rating: number
    name: string
    title: string
    text: string
    timestamp: string
}) {
    return (
        <Flex
            direction="column"
            justifyContent="space-between"
            backgroundColor="gray100"
            borderRadius="md"
            css={css(
                { height: 196, width: 296, padding: 16 },
                responsiveCss("min", "md", {
                    height: scaleValue(290),
                    width: scaleValue(550),
                    padding: 24,
                })
            )}
        >
            <Flex justifyContent="space-between" alignItems="center">
                <RedoitStars rating={props.rating} />
                <Text variant="label">{props.timestamp}</Text>
            </Flex>
            <Flex direction="column" margin={{ y: 20 }} shrink={1} style={{ overflow: "hidden" }}>
                <div
                    css={css({ marginBottom: 4 }, responsiveCss("min", "md", { marginBottom: 12 }))}
                >
                    <Heading level={3}>{props.title}</Heading>
                </div>
                <div style={{ flexShrink: 1 }}>
                    <Body
                        size="md"
                        color="gray400"
                        style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            lineClamp: "3",
                            textOverflow: "ellipsis",
                            WebkitBoxOrient: "vertical",
                        }}
                    >
                        {props.text}
                    </Body>
                </div>
            </Flex>
            <div css={css(responsiveCss("min", "md", { padding: 10 }))}>
                <Heading level={4}>{props.name}</Heading>
            </div>
        </Flex>
    )
}

Component(ReviewCard, {
    name: "ReviewCard",
    gallery: {
        path: "Cards/ReviewCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            title: "Impressive!",
                            rating: 5,
                            name: "John",
                            text: "This service was so impressive, I won't hesitate to use it again.",
                            timestamp: "2 days ago",
                        },
                    },
                ],
            },
            {
                variants: [
                    {
                        props: {
                            title: "Impressive!",
                            rating: 5,
                            name: "John",
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam sem, sagittis et nisl eget, sagittis varius tortor. Aenean id arcu et erat tristique posuere. Integer quis leo dictum, iaculis augue at, fringilla augue. Nullam eu enim mauris.",
                            timestamp: "2 days ago",
                        },
                    },
                ],
            },
        ],
    },
})
