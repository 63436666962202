import { css } from "../ui/helpers/css"
import { Section } from "../../../../packages/editing/Section"
import { Uuid, Image, Url } from "../../../../reactor"
import { PressLogosCarousel } from "../ui/components/carousel/PressLogosCarousel"
import { responsiveSectionSpacing } from "../ui/helpers/css"

function PressLogos(section: {
    /**
     * How fast the carousel scrolls. Given in pixels per second.
     *
     * @default 30
     */
    carouselSpeed: number

    logos: {
        readonly id: Uuid<"PressLogo">
        image: Image
        url?: Url
    }[]
}) {
    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}
        >
            <PressLogosCarousel logos={section.logos} speed={section.carouselSpeed} />
        </div>
    )
}
Section(PressLogos)
