import { useLocation } from "react-router-dom"
import { useLogInWithVipps } from "../../../../../packages/oidc/code-flow/VippsLogin"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { VippsButton, VippsLogo } from "../../ui/components/visual/VippsButton"
import { Flex } from "../../ui/components/base/Flex"
import { Text } from "../../ui/components/typography/Text"
import { css, responsiveCss } from "../../ui/helpers/css"
import { Heading } from "../../ui/components/typography/Heading"
import { Icon } from "../../ui/components/visual/Icon"
import { useCheckoutContext } from "./CheckoutContext"

export type LoginStepProps = {
    /**
     * @default '{"en":"Create new account"}'
     */
    headline: Localized<string>
    /**
     * @default '{"en":"Already have an account? Log in here"}'
     */
    subHeadline: Localized<string>
    /**
     * @default '{"en":"Register with"}'
     */
    registerButton: Localized<string>
}
export function LoginStep(props: LoginStepProps) {
    const { setStep } = useCheckoutContext()
    const localize = useLocalize()
    const { pathname } = useLocation()

    const login = useLogInWithVipps(pathname)
    return (
        <Flex justifyContent="center" style={{ width: "100%", height: "100%" }}>
            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                css={css(responsiveCss("max", "sm", { padding: "16px 0" }))}
            >
                <div style={{ marginBottom: 24 }}>
                    <div css={css(responsiveCss("max", "sm", { display: "none" }))}>
                        <VippsLogo></VippsLogo>
                    </div>
                    <div css={css(responsiveCss("min", "md", { display: "none" }))}>
                        <VippsLogo width={96} height={24}></VippsLogo>
                    </div>
                </div>
                <Flex
                    direction="column"
                    alignItems="center"
                    gap={12}
                    css={css(
                        responsiveCss("max", "sm", { gap: 8, marginBottom: 32 }),
                        responsiveCss("min", "md", { padding: "0 40px", gap: 12, marginBottom: 40 })
                    )}
                >
                    <Heading color="gray500" level={2}>
                        {localize(props.headline)}
                    </Heading>

                    <Text color="gray400" variant="body" size="md">
                        {localize(props.subHeadline)}
                    </Text>
                </Flex>

                <VippsButton
                    onClick={() => {
                        setStep("Shipping")
                        void login()
                    }}
                ></VippsButton>
            </Flex>
        </Flex>
    )
}
