import { useCallback, useEffect, useState } from "react"
import { server } from "../../../server"
import { OIDCUserInfo } from "./OIDCCodeFlow"

export function useLogInWithVipps(
    redirectUrl: string,
    /** In case of multiple vipps service instances, this can be used to disambiguate. */
    serviceName = "vipps"
) {
    return useCallback(async () => {
        try {
            const res = await fetch(
                `${server()}/api/${serviceName}/auth/url?redirectUrl=${encodeURIComponent(redirectUrl)}`
            )
            const json = await res.json()

            window.location.href = json.authUrl
        } catch (e: any) {
            alert(e.message ?? e.detail)
        }
    }, [redirectUrl, serviceName])
}

export function useLogOutWithVipps(
    /** In case of multiple vipps service instances, this can be used to disambiguate. */
    serviceName = "vipps"
) {
    return useCallback(async () => {
        try {
            const url = `${server()}/api/${serviceName}/auth/session`
            await fetch(url, { method: "DELETE" })

            window.location.reload()
        } catch (e: any) {
            alert(e.message ?? e.detail)
        }
    }, [serviceName])
}

export function useVippsMe(serviceName = "vipps") {
    const [me, setMe] = useState<OIDCUserInfo | null>(null)

    useEffect(() => {
        async function fetchMe() {
            const res = await fetch(`${server()}/api/${serviceName}/me`)
            const json = await res.json()
            setMe(json)
        }

        void fetchMe()
    }, [serviceName])

    return me
}

export function VippsLoginButton(props: { redirectUrl: string }) {
    const me = useVippsMe()
    const login = useLogInWithVipps(props.redirectUrl)
    const logout = useLogOutWithVipps()

    if (me?.sub) {
        return (
            <div>
                <div>Logged in as {me.name}</div>
                <button onClick={logout}>Log out</button>
            </div>
        )
    }

    return <button onClick={login}>Login with Vipps</button>
}
