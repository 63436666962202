import type DropinElement from "@adyen/adyen-web/dist/types/components/Dropin"
import type Core from "@adyen/adyen-web/dist/types/core"
import type { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types"
import type { CoreOptions } from "@adyen/adyen-web/dist/types/core/types"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
    getPaymentMethods,
    postAdditionalDetails,
    postPayment,
    useMerchantConfig,
    usePaymentStatus,
} from "../../../studio/client"
import type { LocaleKey } from "../../localization/Locale"
import { useCurrentLocale } from "../../localization/client-side/useLocalize"
import type { GetMerchantConfigDto, PaymentChannel } from "../dtos"
import { appEnvToWebEnv } from "../functions/AdyenEnvironments"
import { ErrorModal } from "./ErrorModal"
import { Assert } from "../../../reactor/Helpers"

// Adyen result code source: https://docs.adyen.com/online-payments/build-your-integration/payment-result-codes/
const AdyenSuccessful = ["Authorised"]
const AdyenPaymentPending = ["Pending", "Received", "PresentToShopper"]

type AdyenFields = {
    adyenOrder: string
    adyenOrderId: string
    locale: LocaleKey
    merchantConfig: GetMerchantConfigDto
    setComplete: (complete: boolean) => void
    auth3ds?: boolean
    testCode?: number
    /**
     * Whether to show the "store details" checkbox in the Adyen component for
     * credit card payments.
     */
    enableStoreDetails?: boolean
}

export function AdyenComponent({
    onSuccess,
    adyenOrder,
    adyenOrderId,
    enableStoreDetails,
}: {
    onSuccess: () => void
    adyenOrder?: string
    adyenOrderId?: string
    enableStoreDetails?: boolean
}) {
    const [searchParams] = useSearchParams()

    adyenOrder = Assert(adyenOrder ?? useParams().adyenOrder)
    adyenOrderId = Assert(adyenOrderId ?? useParams().adyenOrderId)

    const locale = useCurrentLocale()
    const { data: paymentStatus } = usePaymentStatus(adyenOrder ?? null, adyenOrderId ?? null)
    const { data: merchantConfig } = useMerchantConfig(adyenOrder ?? null, adyenOrderId ?? null)
    const [errorDescription, setErrorDescription] = useState<string | undefined>()
    const adyenContainer = useRef(null)
    const { auth3ds, testCode } = getSearchParams(searchParams)
    const [adyenComponent, setComponent] = useState<DropinElement | undefined>(undefined)
    const [complete, setComplete] = useState<boolean>(false)

    useEffect(() => {
        // This flag counteracts double rendering of the Adyen component, which causes issues.
        // Source: https://github.com/adyen-examples/adyen-react-online-payments/blob/main/src/features/payment/Payment.js#L66
        let renderFlag = true
        const shouldLoadAdyen =
            adyenContainer.current !== null &&
            merchantConfig !== undefined &&
            adyenOrder !== undefined &&
            adyenOrderId !== undefined
        if (!shouldLoadAdyen) return

        const adyenFields: AdyenFields = {
            adyenOrder,
            adyenOrderId,
            locale,
            merchantConfig,
            setComplete,
            auth3ds,
            testCode,
            enableStoreDetails,
        }
        import("../../../node_modules/@adyen/adyen-web/dist/adyen.css")
        import("./adyen.css")

        loadAdyen(adyenFields)
            .then((adyenObject) => {
                if (adyenContainer.current !== null && adyenObject !== undefined && renderFlag) {
                    setComponent(adyenObject.create("dropin").mount(adyenContainer.current))
                }
            })
            .catch((_error) => {
                void ErrorModal([
                    "We could not process your payment at this time.",
                    "Please try again in a few minutes.",
                ])
            })

        return () => {
            renderFlag = false
        }
    }, [paymentStatus, merchantConfig, enableStoreDetails])

    useEffect(() => {
        const paymentMade =
            paymentStatus?.status === "complete" || paymentStatus?.status === "pending" || complete
        if (paymentMade && adyenComponent !== undefined) {
            adyenComponent.setStatus("success")
            setTimeout(() => onSuccess(), 2000)
        } else if (paymentStatus?.status === "faulty" || paymentStatus?.status === "inProgress")
            setErrorDescription("There was an error processing your previous payment.")
    }, [adyenComponent, paymentStatus, complete])

    return (
        <>
            {errorDescription && <p>{errorDescription}</p>}
            <div ref={adyenContainer}></div>
        </>
    )
}

function getSearchParams(searchParams: URLSearchParams) {
    let auth3ds
    let testCode
    if (searchParams.get("auth3ds") === "true") auth3ds = true
    const parsedInt = parseInt(searchParams.get("testCode") ?? "")
    if (!isNaN(parsedInt)) testCode = parsedInt
    return { auth3ds, testCode }
}

async function loadAdyen(adyenFields: AdyenFields) {
    // Seems the typing is wrong in the Adyen NPM package, hence this ugly cast
    const AdyenCheckout = (await import("@adyen/adyen-web")) as any as (
        props: CoreOptions
    ) => Promise<Core>
    const { adyenOrder, adyenOrderId, locale, merchantConfig } = adyenFields
    try {
        const response = await getPaymentMethods(adyenOrder, adyenOrderId, locale, "Web")
        return AdyenCheckout({
            amount: response.amount,
            paymentMethodsResponse: response.result as PaymentMethodsResponse,
            clientKey: merchantConfig.adyenClientKey,
            locale: response.adyenLocale,
            environment: appEnvToWebEnv(merchantConfig.adyenEnvironment),
            onSubmit: onSubmit(adyenFields),
            onAdditionalDetails: onAdditionalDetails(adyenFields),
            paymentMethodsConfiguration: {
                card: {
                    enableStoreDetails: adyenFields.enableStoreDetails,
                    hasHolderName: true,
                    holderNameRequired: true,
                },
            },
        })
    } catch (_error) {
        void ErrorModal([
            "We could not process your payment at this time.",
            "Please try again in a few minutes.",
        ])
    }
}

function onSubmit(adyenFields: AdyenFields) {
    const { adyenOrder, adyenOrderId, locale, auth3ds, testCode } = adyenFields
    return async (state: any, adyenComponent: any) => {
        if (!state?.isValid) {
            void ErrorModal([
                "We could not process your payment at this time.",
                "Please try again in a few minutes.",
            ])
            return
        }

        try {
            const body = {
                locale,
                adyenData: state?.data,
                channel: "Web" as PaymentChannel,
                auth3ds,
                testCode,
            }
            const response = await postPayment(adyenOrder, adyenOrderId, body)
            handleAdyenResponse(adyenComponent, response.result, adyenFields)
        } catch (_error) {
            void ErrorModal([
                "We could not process your payment at this time.",
                "Please try again in a few minutes.",
            ])
        }
    }
}

function onAdditionalDetails(adyenFields: AdyenFields) {
    return async (state: any, adyenComponent: any) => {
        try {
            const response = await postAdditionalDetails(
                adyenFields.adyenOrder,
                adyenFields.adyenOrderId,
                state?.data
            )
            handleAdyenResponse(adyenComponent, response.result, adyenFields)
        } catch (_error) {
            void ErrorModal([
                "We could not process your payment at this time.",
                "Please try again in a few minutes.",
            ])
        }
    }
}

function handleAdyenResponse(adyenComponent: any, response: any, adyenFields: AdyenFields) {
    const resultCode = response?.resultCode
    const paymentMade =
        AdyenSuccessful.includes(resultCode) || AdyenPaymentPending.includes(resultCode)

    if (response?.action !== undefined) adyenComponent?.handleAction(response?.action)
    else if (paymentMade) adyenFields.setComplete(true)
    else
        void ErrorModal([
            "We could not process your payment at this time.",
            "Please try again in a few minutes.",
        ])
}
