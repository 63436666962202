import { useState, useEffect, useRef } from "react"
import { css } from "../../helpers/css"
import { Box } from "../base/Box"
import { colors } from "../../constants/colors"
import { Text } from "../typography/Text"
import { boxShadow } from "../../constants/shadow"

const TOP_BAR_HEIGHT = 57
const TOOLTIP_MARGIN = 14
const TOOLTIP_PADDING_Y = 14

type TooltipProps = {
    text: string
}

export const Tooltip = ({ text }: TooltipProps) => {
    const [position, setPosition] = useState<"above" | "below">("above")
    const [leftPosition, setLeftPosition] = useState<string | undefined>()
    const [rightPosition, setRightPosition] = useState<string | undefined>()
    const [hideArrow, setHideArrow] = useState<boolean>(false)
    const tooltipRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipRect = tooltipRef.current.getBoundingClientRect()
            const spaceAbove =
                tooltipRect.top - TOP_BAR_HEIGHT - TOOLTIP_MARGIN - tooltipRect.height
            const spaceLeft = tooltipRect.left
            const spaceRight = window.innerWidth - tooltipRect.right

            if (spaceAbove < 0) {
                setPosition("below")
            }

            if (spaceLeft < tooltipRect.width / 2) {
                setLeftPosition("0")
                setHideArrow(true)
            } else if (spaceRight < tooltipRect.width / 2) {
                setRightPosition(`0%`)
                setHideArrow(true)
            } else {
                setLeftPosition("50%")
                setHideArrow(false)
            }
        }
    }, [])

    return (
        <Box
            ref={tooltipRef}
            css={css({
                position: "absolute",
                [position === "above" ? "bottom" : "top"]: "100%",
                left: leftPosition,
                right: rightPosition,
                transform: leftPosition === "50%" ? "translateX(-50%)" : "none",
                backgroundColor: colors.grayWhite,
                color: colors.gray500,
                padding: `${TOOLTIP_PADDING_Y}px 24px`,
                borderRadius: "16px",
                boxShadow: boxShadow,
                whiteSpace: "nowrap",
                zIndex: 10,
                marginTop: position === "above" ? "0" : TOOLTIP_MARGIN,
                marginBottom: position === "below" ? "0" : TOOLTIP_MARGIN,
                "::after": {
                    content: !hideArrow ? '""' : "none",
                    position: "absolute",
                    [position === "below" ? "top" : "bottom"]: "-12px",
                    left: leftPosition === "50%" ? "50%" : "calc(50% + 12px)",
                    transform: "translateX(-50%)",
                    borderWidth: "7px",
                    borderStyle: "solid",
                    borderColor:
                        position === "below"
                            ? `transparent transparent ${colors.grayWhite} transparent`
                            : `${colors.grayWhite} transparent transparent transparent`,
                },
            })}
        >
            <Text variant="heading" level="4">
                {text}
            </Text>
        </Box>
    )
}
