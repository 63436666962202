/**
 * Step selection bar for the checkout flow.
 * THIS IS A TEMPORARY COMPONENT TO MAKE IT EASIER DURING DEVELOPMENT, SHOULD BE DELETED LATER
 */
export function StepSelect<T extends string>(props: {
    steps: readonly T[]
    currentStep: T
    setCurrentStep: (step: T) => void
}) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "10px", fontSize: "16px", fontWeight: "500" }}>
                Current step:
            </label>
            <select
                value={props.currentStep}
                onChange={(e) => props.setCurrentStep(e.target.value as any)}
                style={{
                    appearance: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    padding: "8px 12px",
                    fontSize: "16px",
                    fontFamily: "inherit",
                    color: "#333",
                    backgroundColor: "#f9f9f9",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    cursor: "pointer",
                    outline: "none",
                }}
            >
                {props.steps.map((step) => (
                    <option key={step} value={step}>
                        {step}
                    </option>
                ))}
            </select>
        </div>
    )
}
