import { useMemo } from "react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Image, ImageToUrl } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { colors } from "../../constants/colors"
import { responsiveCss, responsiveSpacing } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Text } from "../typography/Text"
import { Body } from "../typography/Body"
import { Icon, IconName } from "../visual/Icon"
import { Box } from "../base/Box"
import { LabelNotification } from "../notification/LabelNotification"

export function DeviceCheckoutCard(props: {
    /**
     * The product being checked out.
     */
    product: {
        name: string
        image: Image
    }

    /**
     * Items with more info about the checkout.
     */
    infoItems?: {
        title: string
        subtitle: string
        icon: IconName
    }[]

    /**
     * Price for the product and subscription being checked out.
     */
    checkout: {
        /**
         * The original price.
         */
        originalPrice: number

        /**
         * The checkout price with discsubtracted .
         */
        discountedPrice: number

        /**
         * Currency of the checkout.
         */
        currency: "NOK"
    }

    /**
     * The subscription the customer is signing up for.
     */
    subscription: {
        /**
         * Duration in months.
         */
        duration: number

        /**
         * Localized string for months.
         */
        unit: string
    }

    /**
     * Label to show in the top right corner.
     */
    label?: string
}) {
    const isDiscountedPrice = useMemo(
        () => props.checkout.originalPrice > props.checkout.discountedPrice,
        [props.checkout.originalPrice, props.checkout.discountedPrice]
    )

    return (
        <Flex
            backgroundColor="gray500"
            borderRadius="lg"
            direction="column"
            color="grayWhite"
            css={css(
                { height: 440, position: "relative" },
                responsiveCss("min", "md", { height: 640 }),
                responsiveSpacing("lg", "padding"),
                responsiveSpacing("xl", "paddingTop")
            )}
        >
            {props.label && (
                <div css={css({ position: "absolute" }, responsiveSpacing("lg", ["top", "right"]))}>
                    <LabelNotification color="brand">{props.label}</LabelNotification>
                </div>
            )}
            <Flex
                flex="1 1 auto"
                direction="column"
                alignItems="center"
                shrink={1}
                style={{ borderBottom: `2px solid ${colors.grayWhite}3D` }}
                css={css(responsiveSpacing("lg", "paddingBottom"))}
            >
                <img
                    src={ImageToUrl(props.product.image, { width: 200 })}
                    style={{
                        height: "100%",
                        width: "auto",
                        minWidth: 0,
                        minHeight: 0,
                        flex: "1 1 0",
                    }}
                />
                {props.infoItems?.map((item, index) => (
                    <Flex
                        key={index}
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ backgroundColor: "#31253f", width: "100%" }}
                        css={css(
                            { borderRadius: 8, height: 56 },
                            responsiveCss("min", "md", { borderRadius: 20, height: 80 }),
                            responsiveSpacing("md", ["paddingLeft", "paddingRight"]),
                            index === 0 ? responsiveSpacing("lg", "marginTop") : { marginTop: 8 }
                        )}
                    >
                        <Icon icon={item.icon} margin={{ right: 8 }} />
                        <Box>
                            <Body size="sm">{item.title}</Body>
                            <Body size="xs" style={{ opacity: 0.6 }}>
                                {item.subtitle}
                            </Body>
                        </Box>
                    </Flex>
                ))}
            </Flex>
            <Flex
                flex="0 0 auto"
                justifyContent="space-between"
                alignItems="flex-end"
                css={css(responsiveSpacing("lg", "paddingTop"))}
            >
                <div>
                    <Flex>
                        <Text variant="heading" level="3">
                            {props.product.name}
                        </Text>
                        <Icon icon="chevronDown" margin={{ left: 4 }} />
                    </Flex>
                    <Body size="sm" style={{ opacity: 0.6 }}>
                        {props.subscription.duration} {props.subscription.unit}
                    </Body>
                </div>
                <Flex direction="column" alignItems="flex-end">
                    <Flex alignItems="flex-end">
                        {isDiscountedPrice && (
                            <Body
                                size="sm"
                                color="gray200"
                                style={{ textDecoration: "line-through", marginRight: 4 }}
                            >
                                {props.checkout.originalPrice}
                            </Body>
                        )}
                        <Text
                            variant="heading"
                            level="2"
                            color={isDiscountedPrice ? "brand" : undefined}
                        >
                            {props.checkout.discountedPrice}
                        </Text>
                    </Flex>
                    <Body size="sm" style={{ opacity: 0.6, textTransform: "lowercase" }}>
                        {props.checkout.currency}/mo
                    </Body>
                </Flex>
            </Flex>
        </Flex>
    )
}
Component(DeviceCheckoutCard, {
    name: "DeviceCheckoutCard",
    gallery: {
        path: "Cards/DeviceCheckoutCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            product: {
                                name: "iPhone 15 Pro",
                                image: `${server()}/static/redoit/product-card-product-image3.png` as any as Image,
                            },
                            subscription: {
                                duration: 24,
                                unit: "months lease",
                            },
                            checkout: {
                                originalPrice: 320,
                                discountedPrice: 250,
                                currency: "NOK",
                            },
                            infoItems: [
                                {
                                    title: "Trade-in cashback",
                                    subtitle: "We will deduct 50 kr. for the next 20 months.",
                                    icon: "device",
                                },
                            ],
                            label: "Trade-in",
                        },
                    },
                ],
            },
        ],
    },
})
