import { ChangeEvent, ReactNode, useCallback, useMemo, useRef } from "react"
import { css } from "../../helpers/css"
import { Button } from "./Button"
import { Component } from "../../../../../../packages/editing/Component"
import { OpaqueString } from "../../../../../../reactor"

export function Dropdown<V extends string | OpaqueString<any>>(props: {
    value: V
    options: { value: V; text: string }[]
    onChange?: (e: ChangeEvent<HTMLSelectElement>, val: V) => void
    renderValue?: (val: V) => ReactNode
}) {
    const selectRef = useRef<HTMLSelectElement>(null)

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            props.onChange?.(e, e.target.value as V)
        },
        [props]
    )

    return (
        <Button variant="secondary" icon="chevronDown" size="sm" css={buttonCss}>
            {props.renderValue?.(props.value) ??
                props.options.find((opt) => opt.value === props.value)?.text}
            <select
                css={dropdownCss}
                ref={selectRef}
                value={props.value.valueOf()}
                onChange={handleChange}
            >
                {props.options.map((o) => (
                    <option key={o.value.valueOf()} value={o.value.valueOf()}>
                        {o.text}
                    </option>
                ))}
            </select>
        </Button>
    )
}

const buttonCss = css({
    position: "relative",
})

const dropdownCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    minWidth: 0,
    appearance: "none",
    opacity: 0,
    cursor: "pointer",
})

Component(Dropdown, {
    name: "Dropdown",
    gallery: {
        path: "Buttons/Dropdown",
        items: [
            {
                variants: [
                    {
                        props: {
                            value: "foo",
                            options: [
                                { value: "foo", text: "Foo" },
                                { value: "bar", text: "Bar" },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
