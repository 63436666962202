import { css } from "../ui/helpers/css"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { RangedInteger, Url, Uuid } from "../../../../reactor"
import { Heading } from "../ui/components/typography/Heading"
import { responsiveCss, responsiveSectionSpacing } from "../ui/helpers/css"
import { ReviewsCarousel } from "../ui/components/carousel/ReviewsCarousel"
import { Flex } from "../ui/components/base/Flex"
import { Body } from "../ui/components/typography/Body"
import { Button } from "../ui/components/buttons/Button"
import { Icon } from "../ui/components/visual/Icon"
import { Box } from "../ui/components/base/Box"
import { openWaitingListSignupModal } from "./WaitingListSignupModal"

/**
 * @icon ui-message-heart-square
 */
function PromotedReviews(section: {
    heading: Localized<string>
    text: Localized<string>
    reviews: Review[]
    cta?: {
        text: Localized<string>
        url: Url
    }

    /**
     * How fast the carousel scrolls. Given in pixels per second.
     *
     * @default 30
     */
    carouselSpeed: number
}) {
    const localize = useLocalize()

    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}
        >
            <Flex alignItems="center" justifyContent="space-between">
                <Heading level={2} padding={{ y: 8 }} truncate>
                    {localize(section.heading)}
                </Heading>
            </Flex>
            <Body size="lg" color="gray400" margin={{ bottom: "sm" }}>
                {localize(section.text)}
            </Body>
            <ReviewsCarousel
                reviews={section.reviews.map(({ id, rating, ...r }) => ({
                    id: id.valueOf(),
                    rating: rating.valueOf(),
                    ...r,
                }))}
                speed={section.carouselSpeed}
            />
            {section.cta ? (
                <Box margin={{ top: "sm" }}>
                    <Button
                        size="md"
                        variant="secondary"
                        icon="arrowRight"
                        css={responsiveCss("max", "xs", { width: "100%" })}
                        // Action commented out during August pre-launch.
                        // href={section.cta.url.valueOf()}
                        onClick={() => openWaitingListSignupModal("promoted-reviews")}
                    >
                        <div css={css(responsiveCss("max", "xs", { display: "none" }))}>
                            <Icon icon="trustpilot" margin={{ right: 8 }} />
                        </div>
                        {localize(section.cta.text)}
                    </Button>
                </Box>
            ) : null}
        </div>
    )
}

type Review = {
    readonly id: Uuid<"Review">

    /**
     * @slider
     */
    rating: RangedInteger<"Rating", 1, 5>
    name: string
    title: string
    text: string
    timestamp: string
}

Section(PromotedReviews)
