import { useCallback, useRef, useState } from "react"
import { css } from "@emotion/react"
import { useCookieConsents, putCookieConsents } from "../../../../../../studio/client"
import { Flex } from "../base/Flex"
import { pageSize } from "../../constants/sizes"
import { responsiveCss } from "../../helpers/css"
import { Text } from "../typography/Text"
import { Button } from "../buttons/Button"
import { Checkbox } from "../controllers/Checkbox"
import { AnimatePresence, motion } from "framer-motion"
import { Divider } from "../other/Divider"
import { useLocalize } from "../../../../../../packages/localization/client-side/useLocalize"
import { springAnimations } from "../../constants/animation"

/**
 * A function that is emitted on the window object if SSR has injected Cookie
 * Consent-related JavaScriptin the <head> of the document.
 *
 * Calling this function on the client side will execute all callbacks that were
 * registered with WithCookieConsent for the specified type.
 *
 * This allows the consents to take effect without reloading the page.
 */
declare const GiveCookieConsent: undefined | ((type: string) => void)

/**
 * A default view for presenting cookie consent information and options to the
 * user. This only displays a banner if the user has not yet given consent.
 *
 * This implements a default popup, with allow all, allow selection (with
 * switches for individual consents), and deny buttons.
 *
 * To customize for a particular customer, you can implement your own component,
 * or fork this file.
 */

export function CookieConsentView() {
    const { data, refresh } = useCookieConsents()
    const localize = useLocalize()
    const [necessary, setNecessary] = useState(true)
    const [preferences, setPreferences] = useState(false)
    const [statistics, setStatistics] = useState(false)
    const [marketing, setMarketing] = useState(false)
    const [isCustomize, setIsCustomize] = useState(false)
    const defaultRef = useRef<HTMLDivElement>(null)
    const customizeRef = useRef<HTMLDivElement>(null)

    const handleAllowAllClick = useCallback(async () => {
        await putCookieConsents({
            necessary: true,
            marketing: true,
            preferences: true,
            statistics: true,
        })
        if (GiveCookieConsent) {
            // Calling GiveCookieConsent will execute all
            // callbacks registered with WithCookieConsent for
            // the specified type. This allows the consents to
            // take effect without reloading the page.
            GiveCookieConsent("necessary")
            GiveCookieConsent("marketing")
            GiveCookieConsent("preferences")
            GiveCookieConsent("statistics")
        }
        await refresh()
    }, [refresh])

    const handleDenyClick = useCallback(async () => {
        await putCookieConsents({
            necessary: true, // Necessary cookies are always enabled
            marketing: false,
            preferences: false,
            statistics: false,
        })
        await refresh()
        if (GiveCookieConsent) GiveCookieConsent("necessary")
    }, [refresh])

    const handleAllowSelectionClick = useCallback(async () => {
        await putCookieConsents({
            necessary: true, // Necessary cookies are always enabled
            marketing,
            preferences,
            statistics,
        })
        await refresh()
        if (GiveCookieConsent && necessary) GiveCookieConsent("necessary")
        if (GiveCookieConsent && preferences) GiveCookieConsent("preferences")
        if (GiveCookieConsent && statistics) GiveCookieConsent("statistics")
        if (GiveCookieConsent && marketing) GiveCookieConsent("marketing")
    }, [refresh, marketing, preferences, statistics])

    return (
        <AnimatePresence>
            {data?.consents || !data?.info ? (
                <></>
            ) : (
                <Flex
                    key="cookie-consent-view"
                    css={popupCss}
                    backgroundColor="grayWhite"
                    borderRadius="lg"
                    elevation
                    direction="column"
                    motion={{
                        transition: springAnimations["200"],
                        initial: { transform: "translateY(50px)", scale: 0.75, opacity: 0 },
                        animate: { transform: "translateY(0px)", scale: 1, opacity: 1 },
                        exit: { opacity: 0 },
                    }}
                >
                    <motion.div
                        animate={
                            isCustomize
                                ? {
                                      translateY: (defaultRef.current?.clientHeight ?? 0) * -1,
                                      opacity: 0,
                                      height: 0,
                                  }
                                : {}
                        }
                    >
                        <div ref={defaultRef}>
                            <Text
                                variant="heading"
                                level={["fixed", "md", "3"]}
                                style={{ marginBottom: 16 }}
                            >
                                {localize(data.info.title)}
                            </Text>
                            <Text variant="body" size="md">
                                {localize(data.info.description)}
                            </Text>
                            <Flex margin={{ top: 16 }}>
                                <div style={{ flex: "1 0 0" }}>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        fullwidth
                                        onClick={() => setIsCustomize(true)}
                                    >
                                        {localize(data.info.customize)}
                                    </Button>
                                </div>
                                <div style={{ marginLeft: 8, flex: "1 0 0" }}>
                                    <Button size="sm" onClick={handleAllowAllClick} fullwidth>
                                        {localize(data.info.allowAll)}
                                    </Button>
                                </div>
                            </Flex>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ height: 0, overflow: "hidden", opacity: 0 }}
                        animate={
                            isCustomize
                                ? { height: customizeRef.current?.clientHeight, opacity: 1 }
                                : {}
                        }
                    >
                        <div ref={customizeRef}>
                            <Text
                                variant="heading"
                                level={["fixed", "md", "3"]}
                                style={{ marginBottom: 16 }}
                            >
                                {localize(data.info.customize)}
                            </Text>
                            <Flex margin={{ bottom: 16 }} direction="column" as="label">
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    margin={{ bottom: 8 }}
                                >
                                    <Text variant="body" size="lg">
                                        {localize(data.info.necessary)}
                                    </Text>
                                    <Checkbox
                                        disabled
                                        checked={necessary}
                                        onChange={(e) => setNecessary(e.target.checked)}
                                    />
                                </Flex>
                                <Text variant="body" size="md">
                                    {localize(data.info.necessaryDescription)}
                                </Text>
                            </Flex>
                            <Divider horizontal spacing={16} />
                            <Flex
                                margin={{ bottom: 16 }}
                                direction="column"
                                as="label"
                                style={{ cursor: "pointer" }}
                            >
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    margin={{ bottom: 8 }}
                                >
                                    <Text variant="body" size="lg">
                                        {localize(data.info.preferences)}
                                    </Text>
                                    <Checkbox
                                        checked={preferences}
                                        onChange={(e) => setPreferences(e.target.checked)}
                                    />
                                </Flex>
                                <Text variant="body" size="md">
                                    {localize(data.info.preferencesDescription)}
                                </Text>
                            </Flex>
                            <Divider horizontal spacing={16} />
                            <Flex
                                margin={{ bottom: 16 }}
                                direction="column"
                                as="label"
                                style={{ cursor: "pointer" }}
                            >
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    margin={{ bottom: 8 }}
                                >
                                    <Text variant="body" size="lg">
                                        {localize(data.info.statistics)}
                                    </Text>
                                    <Checkbox
                                        checked={statistics}
                                        onChange={(e) => setStatistics(e.target.checked)}
                                    />
                                </Flex>
                                <Text variant="body" size="md">
                                    {localize(data.info.statisticsDescription)}
                                </Text>
                            </Flex>
                            <Divider horizontal spacing={16} />
                            <Flex
                                margin={{ bottom: 16 }}
                                direction="column"
                                as="label"
                                style={{ cursor: "pointer" }}
                            >
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    margin={{ bottom: 8 }}
                                >
                                    <Text variant="body" size="lg">
                                        {localize(data.info.marketing)}
                                    </Text>
                                    <Checkbox
                                        checked={marketing}
                                        onChange={(e) => setMarketing(e.target.checked)}
                                    />
                                </Flex>
                                <Text variant="body" size="md">
                                    {localize(data.info.marketingDescription)}
                                </Text>
                            </Flex>
                            <Flex margin={{ top: 16 }}>
                                <div style={{ flex: "1 0 0" }}>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        fullwidth
                                        onClick={handleDenyClick}
                                    >
                                        {localize(data.info.deny)}
                                    </Button>
                                </div>
                                <div style={{ marginLeft: 8, flex: "1 0 0" }}>
                                    <Button size="sm" onClick={handleAllowSelectionClick} fullwidth>
                                        {localize(data.info.allowSelection)}
                                    </Button>
                                </div>
                            </Flex>
                        </div>
                    </motion.div>
                </Flex>
            )}
        </AnimatePresence>
    )
}

const popupCss = css(
    css`
        z-index: 1000;
        position: fixed;
        bottom: ${pageSize.xs.paddingBottom}px;
        left: ${pageSize.xs.paddingLeft}px;
        right: ${pageSize.xs.paddingLeft}px;
        padding: 16px;
        overflow: hidden;
    `,
    responsiveCss(
        "min",
        "sm",
        css`
            width: 400px;
        `
    ),
    responsiveCss(
        "min",
        "md",
        css`
            bottom: ${pageSize.md.paddingBottom}px;
            left: ${pageSize.md.paddingLeft}px;
            padding: 24px;
            width: 400px;
        `
    )
)
