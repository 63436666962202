import {
    useCallback,
    ChangeEvent,
    FocusEvent,
    useState,
    useMemo,
    forwardRef,
    ForwardedRef,
    HTMLInputAutoCompleteAttribute,
} from "react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { resetStyles, responsiveCss } from "../../helpers/css"
import { Box } from "../base/Box"
import { Icon, IconName } from "../visual/Icon"
import { type Color, colors } from "../../constants/colors"
import { xlScaleFactor } from "../../constants/sizes"
import { responsiveBodyCss } from "../typography/Body"
import { WarningMessage } from "../inputs/WarningMessage"

type State = "error" | "completed"
type InternalState = "default" | "active" | "error" | "completed"

const borderColors: { [key in InternalState]: Color } = {
    default: "gray200",
    active: "brand",
    error: "error",
    completed: "forest",
}

export const TextInput = forwardRef(function TextInput(
    props: {
        /**
         * The type of values this text field is for.
         */
        type?: "text" | "email" | "tel" | "password" | "search" | "url"

        /**
         * The type of autocomplete which is relevant for this text field.
         */
        autoComplete?: HTMLInputAutoCompleteAttribute

        /**
         * The input field label.
         */
        label?: string

        /**
         * The value of the input field.
         */
        value?: string

        /**
         * Show an icon in the left part of the input field.
         */
        icon?: IconName

        /**
         * Apply style of error or completed to the input field.
         */
        state?: State

        /**
         * A warning message to display with the input field.
         */
        warning?: string

        /**
         * @reflection any
         */
        onChange?: (e: ChangeEvent<HTMLInputElement>) => void
        /**
         * @reflection any
         */
        onBlur?: (e: FocusEvent<HTMLInputElement>) => void
    },
    ref: ForwardedRef<HTMLInputElement>
) {
    const [isActive, setIsActive] = useState(false)
    const state = useMemo<InternalState>(() => {
        if (props.state) return props.state
        if (isActive) return "active"
        return "default"
    }, [props.state, isActive])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (typeof props.onChange === "function") props.onChange(e)
        },
        [props]
    )

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            setIsActive(false)
            props.onBlur?.(e)
        },
        [setIsActive, props]
    )

    return (
        <Box>
            <Box
                borderColor={borderColors[state]}
                borderRadius="sm"
                css={css(
                    {
                        height: 48 * xlScaleFactor,
                    },
                    responsiveCss("min", "md", {
                        height: 64 * xlScaleFactor,
                    })
                )}
                style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 20 * xlScaleFactor,
                    paddingRight: 20 * xlScaleFactor,
                    borderWidth: 2,
                }}
            >
                {!!props.icon && <Icon icon={props.icon} style={{ marginRight: 10 }} />}
                <input
                    ref={ref}
                    onFocus={() => setIsActive(true)}
                    onBlur={handleBlur}
                    type={props.type ?? "text"}
                    autoComplete={props.autoComplete}
                    placeholder={props.label}
                    css={inputCss}
                    size={2}
                    style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        paddingBottom: 2,
                        color: state === "error" ? colors.error : undefined,
                    }}
                    value={props.value}
                    onChange={handleChange}
                />
                {state === "completed" && (
                    <Icon icon="check" color="forest" style={{ marginLeft: 10 }} />
                )}
            </Box>
            {props.warning ? <WarningMessage>{props.warning}</WarningMessage> : null}
        </Box>
    )
})

const inputCss = css(
    resetStyles("input"),
    { "::placeholder": { color: colors.gray300 } },
    responsiveBodyCss("md")
)

Component(TextInput as any, {
    name: "TextInput",
    gallery: {
        path: "Controllers/TextInput",
        items: [
            {
                title: "Empty",
                variants: [
                    {
                        props: (state = { value: "" }, setState) => ({
                            value: state.value,
                            label: "First name",
                            onChange: (e: any) => setState(e.target.value),
                        }),
                    },
                ],
            },
            {
                title: "With value",
                variants: [
                    {
                        props: (state = { value: "Jane" }, setState) => ({
                            value: state.value,
                            onChange: (e: any) => setState(e.target.value),
                        }),
                    },
                ],
            },
            {
                title: "With icon",
                variants: [
                    {
                        props: (state = { value: "Oslo" }, setState) => ({
                            value: state.value,
                            icon: "navigationArrow" as IconName,
                            onChange: (e: any) => setState(e.target.value),
                        }),
                    },
                ],
            },
            {
                title: "Completed",
                variants: [
                    {
                        props: (state = { value: "John Doe" }, setState) => ({
                            value: state.value,
                            state: "completed" as State,
                            onChange: (e: any) => setState(e.target.value),
                        }),
                    },
                ],
            },
            {
                title: "Error",
                variants: [
                    {
                        props: (state = { value: "John Doe" }, setState) => ({
                            value: state.value,
                            state: "error" as State,
                            onChange: (e: any) => setState(e.target.value),
                        }),
                    },
                ],
            },
        ],
    },
})
