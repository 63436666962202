import { useEffect } from "react"
import { Global } from "@emotion/react"
import { useSessionState } from "../../../../reactor/Web"
import { Uuid } from "../../../../reactor"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Section } from "../../../../packages/editing/Section"
import { TradeInStepProps } from "./checkout/TradeInStep"
import { InsuranceStepProps } from "./checkout/InsuranceStep"
import { LoginStepProps } from "./checkout/LoginStep"
import { ShippingStepProps } from "./checkout/ShippingStep"
import { PaymentStepProps } from "./checkout/PaymentStep"
import { ConfirmationStepProps } from "./checkout/ConfirmationStep"
import { CheckoutContext, CheckoutStep, checkoutSteps } from "./checkout/CheckoutContext"
import { checkoutNavigation } from "./checkout/CheckoutContext"
import { PhoneOffer, TradeInPriceRequest, usePhoneOffers, usePhoneOrderPrice } from "../client"
import { StepSelect } from "../ui/components/controllers/StepSelection"
import { Steps } from "../ui/components/navigation/Steps"
import { DeviceCheckoutCard } from "../ui/components/cards/DeviceCheckoutCard"
import { Flex } from "../ui/components/base/Flex"
import { css, responsiveCss } from "../ui/helpers/css"
import { colors } from "../ui/constants/colors"
import { pageSize } from "../ui/constants/sizes"

export function useCurrentPhoneOffer() {
    return useSessionState<PhoneOffer | undefined>("offer", undefined)
}

/**
 * Pages that are not done yet should have a pink background
 * Can be removed after all css is implemented
 */
const getBackground = (step: CheckoutStep) => {
    if (step === "TradeIn" || step === "Insurance" || step === "Login" || step === "Shipping") {
        return colors.gray100
    }
    return "#fee"
}

function Checkout(section: {
    TradeIn: TradeInStepProps
    Insurance: InsuranceStepProps
    Login: LoginStepProps
    Shipping: ShippingStepProps
    Payment: PaymentStepProps
    Confirmation: ConfirmationStepProps
}) {
    const localize = useLocalize()
    const [step, setStep] = useSessionState<CheckoutStep>("step", "TradeIn")

    const [offer, setOffer] = useCurrentPhoneOffer()

    const [shippingOption, setShippingOption] = useSessionState<
        Uuid<"ManualShippingSlot"> | undefined
    >("shippingOption", undefined)

    const [tradeIn, setTradeIn] = useSessionState<TradeInPriceRequest | undefined>(
        "tradeIn",
        undefined
    )

    const price = usePhoneOrderPrice(offer ? { phone: offer, shippingOption, tradeIn } : null)

    const [orderId, setOrderId] = useSessionState<Uuid<"PhoneOrder"> | undefined>(
        "order-id",
        undefined
    )

    if (!offer) return <></>

    const StepContent: any = checkoutSteps[step]

    return (
        <CheckoutContext.Provider
            value={{
                offer,
                setOffer,
                price: price.data,
                shippingOption,
                setShippingOption,
                tradeIn,
                setTradeIn,
                step,
                setStep,
                orderId,
                setOrderId,
            }}
        >
            <Global styles={{ body: { backgroundColor: colors.gray100 } }} />
            <div style={{ margin: "25px 0" }}>
                <Steps steps={checkoutNavigation} currentStep={step}></Steps>
            </div>
            <Flex
                gap={24}
                css={css(responsiveCss("max", "sm", { flexDirection: "column" }))}
                style={{
                    backgroundColor: getBackground(step),
                }}
            >
                <Flex
                    flex={1}
                    direction="column"
                    borderRadius="lg"
                    backgroundColor="grayWhite"
                    css={css(
                        responsiveCss("max", "md", {
                            padding: pageSize.md.paddingBottom,
                        })
                    )}
                >
                    <StepContent {...section[step]} />
                </Flex>
                <div style={{ flex: 0.7 }}>
                    {offer.images && offer.images[0] ? (
                        <DeviceCheckoutCard
                            subscription={{ duration: offer.rentalPeriod ?? 0, unit: "months" }}
                            checkout={{
                                currency: "NOK",
                                originalPrice:
                                    (price.data?.monthlyCost.total.majorUnits ?? 0) -
                                    (price?.data?.monthlyCost.tradeInDiscount?.majorUnits ?? 0),
                                discountedPrice: price.data?.monthlyCost.total.majorUnits ?? 0,
                            }}
                            product={{
                                name: localize(offer.name),
                                image: offer.images[0] as any,
                            }}
                        />
                    ) : null}
                </div>
            </Flex>
            <Flex
                borderRadius="sm"
                elevation
                css={css(
                    {
                        bottom: 16,
                        left: 16,
                        right: 16,
                    },
                    responsiveCss("min", "md", {
                        bottom: pageSize.md.paddingBottom,
                        right: pageSize.md.paddingRight,
                        left: pageSize.md.paddingLeft,
                    })
                )}
                style={{
                    position: "fixed",
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    padding: 24,
                    backgroundColor: "white",
                }}
            >
                <StepSelect<CheckoutStep>
                    steps={Object.keys(checkoutSteps) as CheckoutStep[]}
                    currentStep={step}
                    setCurrentStep={setStep}
                />
            </Flex>
        </CheckoutContext.Provider>
    )
}
Section(Checkout)
