import { createContext, useContext } from "react"
import { PhoneOffer, PhoneOrderPrice, TradeInPriceRequest } from "../../client"
import { ConfirmationStep } from "./ConfirmationStep"
import { InsuranceStep } from "./InsuranceStep"
import { LoginStep } from "./LoginStep"
import { PaymentStep } from "./PaymentStep"
import { ShippingStep } from "./ShippingStep"
import { TradeInStep } from "./TradeInStep"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { IconName } from "../../ui/components/visual/Icon"

export const checkoutSteps = {
    TradeIn: TradeInStep,
    Insurance: InsuranceStep,
    Login: LoginStep,
    Shipping: ShippingStep,
    Payment: PaymentStep,
    Confirmation: ConfirmationStep,
}

export type CheckoutStep = keyof typeof checkoutSteps

export const checkoutNavigation: { name: string; icon: IconName; tooltip: string }[] = [
    {
        name: "TradeIn",
        icon: "handCoins",
        tooltip: "Bytte inn",
    },
    {
        name: "Insurance",
        icon: "shield",
        tooltip: "Forsikring",
    },
    {
        name: "Login",
        icon: "vipps",
        tooltip: "Vipps ID",
    },
    {
        name: "Shipping",
        icon: "truck",
        tooltip: "Shipping",
    },
    {
        name: "Payment",
        icon: "receipt",
        tooltip: "Check-out",
    },
]

export type CheckoutContext = {
    offer: PhoneOffer
    setOffer: (offer: PhoneOffer) => void
    shippingOption: Uuid<"ManualShippingSlot"> | undefined
    setShippingOption: (shippingOption: Uuid<"ManualShippingSlot"> | undefined) => void
    tradeIn: TradeInPriceRequest | undefined
    setTradeIn: (tradeIn: TradeInPriceRequest | undefined) => void
    price?: PhoneOrderPrice
    step: CheckoutStep
    setStep: (step: CheckoutStep) => void
    orderId: Uuid<"PhoneOrder"> | undefined
    setOrderId: (order: Uuid<"PhoneOrder"> | undefined) => void
}

export const CheckoutContext = createContext<CheckoutContext | undefined>(undefined)

export function useCheckoutContext(): CheckoutContext {
    const context = useContext(CheckoutContext)
    if (!context) throw new Error("CheckoutContext not found")
    return context
}
