import { useEffect, useState } from "react"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../../packages/localization/Localized"
import { Flex } from "../../ui/components/base/Flex"
import { Button } from "../../ui/components/buttons/Button"
import { Modal } from "../../ui/components/modal/Modal"
import { Heading } from "../../ui/components/typography/Heading"
import { Text } from "../../ui/components/typography/Text"
import { Icon } from "../../ui/components/visual/Icon"
import { css, responsiveCss } from "../../ui/helpers/css"
import { useCheckoutContext } from "./CheckoutContext"
import { postTradeInFormsValue, PostTradeInFormsValueDto, useTradeInForm } from "../../client"
import { colors } from "../../ui/constants/colors"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { Assert } from "../../../../../reactor"
import { Dropdown } from "../../ui/components/buttons/Dropdown"

export type TradeInStepProps = {
    /**
     * @default '{"en":"Trade in your old device"}'
     */
    headline: Localized<string>

    /**
     * @default '{"en":"How does it work?"}'
     */
    ingress: Localized<string>

    /**
     * @default '[{"en":"Get a quote for your old device"},{"en":"Send in your old device"},{"en":"Get paid"}]'
     */
    steps: Localized<string>[]

    notThisTimeButton: Localized<string>

    primaryCta: Localized<string>
}
export function TradeInStep(props: TradeInStepProps) {
    const localize = useLocalize()
    const { setStep } = useCheckoutContext()

    const [modalOpen, setModalOpen] = useState(false)

    function startTradeIn() {
        setModalOpen(true)
    }

    return (
        <Flex direction="column" style={{ width: "80%", margin: "auto" }}>
            <div style={{ marginBottom: "32px" }}>
                <Heading level={2}>{localize(props.headline)}</Heading>
            </div>
            <Flex direction="column">
                <div style={{ marginBottom: "20px" }}>
                    <Text variant="body" size="xl">
                        {localize(props.ingress)}
                    </Text>
                </div>
                {props.steps.map((item, i) => (
                    <Flex
                        key={i}
                        gap={8}
                        alignItems="center"
                        css={css(responsiveCss("min", "md", { marginBottom: 4 }))}
                    >
                        <Icon icon="check" color="forest" size={24} />
                        <div>
                            <Text variant="body" color="gray300" size="lg">
                                {localize(item)}
                            </Text>
                        </div>
                    </Flex>
                ))}
            </Flex>
            <Flex
                gap={8}
                margin={{ top: 32 }}
                css={css(responsiveCss("max", "sm", { flexDirection: "column-reverse" }))}
            >
                <Button variant="secondary" onClick={() => setStep("Insurance")}>
                    {localize(props.notThisTimeButton) ?? "Skip"}
                </Button>
                <Button variant="primary" onClick={startTradeIn}>
                    {localize(props.primaryCta) ?? "Start trade in"}
                </Button>
            </Flex>
            <TradeInModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                    setStep("Insurance")
                }}
            />
        </Flex>
    )
}

function TradeInModal(props: { open: boolean; onClose: () => void }) {
    const { data: form } = useTradeInForm("Phones")
    const context = useCheckoutContext()
    const [responses, setResponses] = useState([] as (Uuid<"TradeInResponse"> | undefined)[])

    useEffect(() => {
        if (form) {
            setResponses(form.questions.map(() => undefined))
        }
    }, [form?.questions.length])

    const localize = useLocalize()

    const [step, setStep] = useState<"form" | "summary">("form")
    const [result, setResult] = useState<PostTradeInFormsValueDto | undefined>(undefined)

    return (
        <Modal
            isOpen={props.open}
            onClose={props.onClose}
            onBack={() => setStep("form")}
            header={{
                closeButton: true,
                backButton: step === "summary",
            }}
        >
            {step === "form" ? (
                <>
                    <Heading level={2}>Trade in your old device</Heading>
                    {form?.questions.map((q, qi) => (
                        <div
                            style={{
                                marginTop: 32,
                                borderTop: qi > 0 ? `1px solid ${colors.gray200}` : undefined,
                            }}
                        >
                            <div>{localize(q.question)}</div>
                            {q.type === "buttons" ? (
                                q.responses.map((r, ri) => (
                                    <Button
                                        variant="select"
                                        selected={responses[qi] === r.id}
                                        onClick={() => {
                                            responses[qi] = r.id
                                            setResponses([...responses])
                                        }}
                                    >
                                        {localize(r.response)}
                                    </Button>
                                ))
                            ) : q.type === "dropdown" ? (
                                <Dropdown<Uuid<"TradeInResponse"> | "">
                                    value={responses[qi] ?? ""}
                                    onChange={(e, val) => {
                                        responses[qi] = val as any
                                        setResponses([...responses])
                                    }}
                                    options={[
                                        { value: "", text: "" },
                                        ...q.responses.map((r) => ({
                                            value: r.id,
                                            text: localize(r.response),
                                        })),
                                    ]}
                                    renderValue={(val) =>
                                        val === ""
                                            ? localize({
                                                  en: "Please select",
                                                  no: "Vennligst velg",
                                              })
                                            : localize(
                                                  q.responses.find((x) => x.id === val)?.response!
                                              )
                                    }
                                />
                            ) : (
                                <div>Unsupported question type: {q.type}</div>
                            )}
                        </div>
                    ))}
                    <Button
                        margin={{ y: 32 }}
                        variant="primary"
                        onClick={async () => {
                            setStep("summary")
                            try {
                                const response = await postTradeInFormsValue("Phones", {
                                    months: context.offer.rentalPeriod,
                                    responses: responses.map((r) => Assert(r)),
                                })
                                setResult(response)
                            } catch (e: any) {
                                if ("detail" in e) alert(e.detail)
                                else alert("An error occurred")
                            }
                        }}
                        disabled={!responses.every((r) => r !== undefined)}
                    >
                        {localize({ en: "Continue", no: "Neste" })}
                    </Button>
                </>
            ) : (
                <>
                    <Heading level={2}>What am I paying for?</Heading>
                    <div>Summary goes here</div>
                    <div>Discount: {result?.monthlyDiscount?.majorUnits}</div>
                    <Heading level={2}>Summary?</Heading>
                    <Button onClick={() => setStep("form")}>Edit</Button>
                    {form?.questions.map((q, qi) => (
                        <div
                            style={{
                                marginTop: 32,
                                borderTop: qi > 0 ? `1px solid ${colors.gray200}` : undefined,
                            }}
                        >
                            <div>{localize(q.question)}</div>
                            {localize(q.responses.find((r) => r.id === responses[qi])!.response)}
                        </div>
                    ))}
                    <Button
                        onClick={() => {
                            context.setTradeIn({
                                form: form!.id,
                                responses: responses.map((r) => Assert(r)),
                                version: form!.version,
                            })
                            props.onClose()
                        }}
                    >
                        Ok, continue
                    </Button>
                </>
            )}
        </Modal>
    )
}
