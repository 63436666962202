import { css } from "../ui/helpers/css"
import { Uuid } from "../../../../reactor"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { UniqueSellingPoints as USPComponent } from "../ui/components/UniqueSellingPoints"
import { responsiveCss, scaleValue } from "../ui/helpers/css"

function UniqueSellingPoints(section: {
    /**
     * @expand
     */
    uniqueSellingPoints: {
        readonly id: Uuid<"UniqueSellingPoint">

        /**
         * The text for the USP.
         */
        text: Localized<string>
    }[]
}) {
    const localize = useLocalize()
    return (
        <div
            css={css(
                { paddingTop: scaleValue(28), paddingBottom: scaleValue(12) },
                responsiveCss("min", "md", {
                    paddingTop: scaleValue(28),
                    paddingBottom: scaleValue(28),
                })
            )}
        >
            <USPComponent items={section.uniqueSellingPoints.map((item) => localize(item.text))} />
        </div>
    )
}

Section("UniqueSellingPoints", UniqueSellingPoints)
