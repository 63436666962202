import { useEffect, useState } from "react"
import { Section } from "../../../../packages/editing/Section"
import { useQueryString } from "../../../../reactor/Web"
import { getPhoneOffer, GetPhoneOfferOptionsDto, PhoneOffer, postPhoneOffer } from "../client"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Heading } from "../ui/components/typography/Heading"
import { ImageToUrl } from "../../../../reactor"
import { RadioButton } from "../ui/components/controllers/Radio"
import { Button } from "../ui/components/buttons/Button"
import { useCurrentPhoneOffer } from "./Checkout"

Section(ProductDetails)
function ProductDetails(section: {}) {
    const [product] = useQueryString("phone_model")
    const [phone, setPhone] = useCurrentPhoneOffer()
    const [options, setOptions] = useState<GetPhoneOfferOptionsDto | undefined>()

    // Download the initial phone offer configuration and set it as state to be
    // used in the UI.
    useEffect(() => {
        if (product)
            getPhoneOffer(product as any).then((res) => {
                setPhone(res.offer)
                setOptions(res.options)
            })
    }, [product])

    const localize = useLocalize()

    if (!phone || !options) return <></>

    return (
        <div style={{ padding: 64 }}>
            <Heading level={2}>{localize(phone.name)}</Heading>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {phone.images && phone.images[0] && (
                    <img
                        src={ImageToUrl(phone.images[0], { width: 320 })}
                        alt={localize(phone.name)}
                    />
                )}
                <div>
                    <div style={{ marginTop: 16 }}>
                        <Heading level={3}> Color</Heading>
                        {options.colors.map((color) => (
                            <div key={color.name} style={{ display: "flex", flexDirection: "row" }}>
                                {localize(color.displayName)}
                                <div
                                    style={{
                                        width: 32,
                                        height: 32,
                                        backgroundColor: color.color,
                                        borderRadius: 16,
                                    }}
                                />
                                <RadioButton
                                    checked={phone.color === color.name}
                                    onClick={async () => {
                                        setPhone(
                                            await postPhoneOffer("color", {
                                                ...phone,
                                                color: color.name,
                                            })
                                        )
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <Heading level={3}> Storage</Heading>
                        {options.storageSizes.map((storage) => (
                            <div
                                key={storage.name}
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {localize(storage.displayName)}
                                <RadioButton
                                    checked={phone.storage === storage.name}
                                    onClick={async () => {
                                        setPhone(
                                            await postPhoneOffer("storage", {
                                                ...phone,
                                                storage: storage.name,
                                            })
                                        )
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <div style={{ marginTop: 16 }}>
                        <Heading level={3}> Rental period</Heading>
                        {options.rentalPeriods.map((rp) => (
                            <div key={rp.months} style={{ display: "flex", flexDirection: "row" }}>
                                {localize(rp.displayName)}
                                <RadioButton
                                    checked={phone.rentalPeriod === rp.months}
                                    onClick={async () => {
                                        setPhone(
                                            await postPhoneOffer("rentalPeriod", {
                                                ...phone,
                                                rentalPeriod: rp.months,
                                            })
                                        )
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <div>
                        Price: {phone.monthlyPrice.majorUnits} {phone.monthlyPrice.currency} / month
                        <Button variant="primary" href="/checkout">
                            Rent this phone
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
