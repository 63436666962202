import { cloneElement, useEffect, useMemo, useRef, useState } from "react"
import { keyframes } from "@emotion/react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Uuid } from "../../../../../../reactor"
import { responsiveSpacing } from "../../helpers/css"
import { ReviewCard } from "../cards/ReviewCard"

const defaultSpeed = 30

/**
 * To get the continuous loop, we need to render an extra set of items. They are hidden from
 * screen readers with aria-hidden.
 */
export function ReviewsCarousel(props: {
    /**
     * Carousel speed in pixels per second.
     */
    speed?: number

    reviews: {
        id: string
        rating: number
        name: string
        title: string
        text: string
        timestamp: string
    }[]
}) {
    const ref = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setWidth(ref.current?.getBoundingClientRect().width || 0)
    }, [])

    const animationDuration = useMemo(
        () => `${((width ?? 0) / (props.speed ?? defaultSpeed)) * 1000}ms`,
        [props.speed, width]
    )

    const reviewElements = useMemo(
        () =>
            cloneElement(
                <>
                    {props.reviews.map(({ id, ...review }) => (
                        <div key={id} css={responsiveItemCss}>
                            <ReviewCard {...review} />
                        </div>
                    ))}
                </>
            ),
        [props.reviews]
    )

    return (
        <div
            style={{
                position: "relative",
                flexWrap: "nowrap",
                display: "flex",
            }}
            css={containerCss}
        >
            <div
                ref={ref}
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
            <div
                aria-hidden
                style={{
                    animationDuration,
                }}
                css={[responsiveGridCss, animationCss]}
            >
                {reviewElements}
            </div>
        </div>
    )
}

const marquee = keyframes({
    from: {
        transform: "translateX(-200%)",
    },
    to: {
        transform: "translateX(-300%)",
    },
})

const animationCss = css({
    animationName: marquee,
})

const responsiveGridCss = css({
    display: "inline-flex",
    alignItems: "center",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
})

const containerCss = css({
    "&:hover div": {
        animationPlayState: "paused",
    },
})

const responsiveItemCss = css(responsiveSpacing("sm", "marginLeft"))

Component(ReviewsCarousel, {
    name: "ReviewsCarousel",
    gallery: {
        path: "Carousel",
        items: [
            {
                variants: [
                    {
                        props: {
                            reviews: [...new Array(3)].map((_, index, arr) => ({
                                id: Uuid().valueOf(),
                                title: "Impressive!",
                                rating: 5,
                                name: "John",
                                text: "This service was so impressive, I won't hesitate to use it again.",
                                timestamp: `${arr.length - index} days ago`,
                            })),
                        },
                    },
                ],
            },
        ],
    },
})
